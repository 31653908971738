import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public menuIndex = 'home';
  
  constructor() { }

  ngOnInit() {
  }

  goToComposing(){
    window.open('https://www.composingstudio.com');
  }

  goToLinkedin(){
    window.open('https://www.linkedin.com/company/groupock');
  }
}
