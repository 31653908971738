import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-modal-stock-create',
  templateUrl: './modal-stock-create.component.html',
  styleUrls: ['./modal-stock-create.component.scss']
})
export class ModalStockCreateComponent implements OnInit {

  public numCount = 1 ;

  constructor(public dialogRef: MatDialogRef<ModalStockCreateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    for(var numCount=1; numCount<490; numCount++){
      var num = Math.floor(Math.random() * 10)*1000;
      setTimeout(()=>{
        this.numCount = this.numCount+1;
        if(this.numCount==489){
          console.log(numCount);
          setTimeout(()=>{
            this.close();
          },2000);
        }
      },num);    
    }
  }

  ngOnInit() {

  }

  close(){
    this.dialogRef.close();
  }
}
