import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss']
})
export class StockListComponent implements OnInit {

  public userData;
  public stock_list = {};
  public stock_list_menu = {};

  objectKey = Object.keys;
  public numStock_list = 0;

  public spinnerStock = true;

  constructor(public db: AngularFireDatabase, public afAuth: AngularFireAuth,) {
    this.afAuth.auth.onAuthStateChanged( user => {
      if (user){
        console.log(user);
        this.db.object('users/'+user.uid).valueChanges().subscribe( action =>{
          console.log(action);
          if(action){
            this.userData=action;
          }
        });

        this.db.object('stock').valueChanges().subscribe( action =>{
          console.log(action);
          if(action){
            var stock_list = {};
            stock_list = action;
    
            for(var chiave in stock_list){
              if(stock_list[chiave].user.uid==this.userData.uid && stock_list[chiave].step==5){
                this.stock_list[chiave] = stock_list[chiave];
                this.stock_list_menu[chiave] = false;
              }
            }

            this.numStock_list = Object.keys(this.stock_list).length;
            this.spinnerStock=false;
          }
        });
      }
    });
  }

  ngOnInit() {
  }

  openMenustock(chiave){
    if(this.stock_list_menu[chiave]==true){
      this.stock_list_menu[chiave]=false;
    }else{
      this.stock_list_menu[chiave]=true;
    }
  }

  countFile(file){
    console.log(file);
    if(file){
      return Object.keys(file).length;
    }else{
      return 0;
    }
  }

  countFoto(foto){
    console.log(foto);
    if(foto){
      return Object.keys(foto).length;
    }else{
      return 0;
    }
  }

  countVideo(video){
    console.log(video);
    if(video){
      return Object.keys(video).length;
    }else{
      return 0;
    }
  }

}
