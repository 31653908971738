import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { auth } from 'firebase';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalResetPasswordComponent } from '../../modules/modal-reset-password/modal-reset-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public param;
  public spinnerLogin = false;
  public message = null;
  public signupForm: FormGroup;

  constructor(private route: ActivatedRoute, private router: Router, public formBuilder: FormBuilder, public authProvider: AuthService, public dialog: MatDialog,) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.param = params['param']; 
      console.log(this.param);
    });

    this.signupForm = formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      surname: new FormControl('', Validators.compose([Validators.required])),
      azienda: new FormControl('', Validators.compose([Validators.required])),
      tipo: new FormControl('negozio regolare', Validators.compose([Validators.required])),
      indirizzo: new FormControl('', Validators.compose([Validators.required])),
      paese: new FormControl('', Validators.compose([Validators.required])),
      partita_iva: new FormControl('', Validators.compose([Validators.required])),
      telefono: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
      password: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  ngOnInit() {
    
  }

  signupUser(){
    this.spinnerLogin = true;
    if (!this.signupForm.valid){
      this.spinnerLogin = false;
      console.log(this.signupForm.value);
      this.message='Inserisci tutti i dati'
    } else {
      this.authProvider.signupUser(this.signupForm.value.email, this.signupForm.value.password, this.signupForm.value.name, this.signupForm.value.surname, this.signupForm.value.azienda, this.signupForm.value.tipo, this.signupForm.value.indirizzo, this.signupForm.value.paese, this.signupForm.value.partita_iva, this.signupForm.value.telefono)
      .then(() => {
        this.spinnerLogin = false;
        this.router.navigate(['/home']);
      }, (error) => {
          this.spinnerLogin = false;
          if(error.code=='auth/email-already-in-use'){
            this.message = 'Errore. Questo indirizzo email è gia in uso.'
          }else{
            this.message = error;
          }
          console.log(error);
      });
    }
  }

  loginUser() {
    this.spinnerLogin=true;
    console.log(this.signupForm.value.email, this.signupForm.value.password);
    this.message=null;
    if (!this.signupForm.value.email || !this.signupForm.value.password){
      console.log(this.signupForm.value);
      this.message='Inserisci email e password';
    } else {
      this.authProvider.loginUser(this.signupForm.value.email, this.signupForm.value.password)
      .then( authData => {
        console.log(authData);
        this.spinnerLogin=false;
        this.router.navigate(['/home']);
      }, error => {
        this.spinnerLogin=false;
        if(error=='Error: The password is invalid or the user does not have a password.'){
          this.message = 'Errore. Email e password non coincidono.'
        }else if(error='Error: The email address is badly formatted.'){
          this.message = 'Errore. Inserisci un indirizzo email valido.';
        }else{
          this.message = error;
        }
        console.log(error);
      }).catch(error => {
        this.spinnerLogin=false;
        console.log(error);
        this.message=error;
      });
    }
  }

  goModalResetPassword(){
    let dialogRef = this.dialog.open(ModalResetPasswordComponent,{
  
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
