import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ContactComponent } from './pages/contact/contact.component';
import { StockCreateComponent } from './pages/stock/stock-create/stock-create.component';
import { StockListComponent } from './pages/stock/stock-list/stock-list.component';
import { StockListAdminComponent } from './pages/stock/stock-list-admin/stock-list-admin.component';
import { StockListAdminDetailComponent } from './pages/stock/stock-list-admin-detail/stock-list-admin-detail.component';
import { TermsComponent } from './pages/terms/terms.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'login/:param',
    component: LoginComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'stock-create',
    component: StockCreateComponent,
  },
  {
    path: 'stock-list',
    component: StockListComponent,
  },
  {
    path: 'stock-list-admin',
    component: StockListAdminComponent,
  },
  {
    path: 'stock-list-admin-detail/:idstock',
    component: StockListAdminDetailComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


