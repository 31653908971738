import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalStockCreateComponent } from '../../../modules/modal-stock-create/modal-stock-create.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Http } from '@angular/http';

@Component({
  selector: 'app-stock-create',
  templateUrl: './stock-create.component.html',
  styleUrls: ['./stock-create.component.scss']
})
export class StockCreateComponent implements OnInit {

  public stockForm: FormGroup;
  public spinnerIndietro = false;
  public spinnerAvanti = false;
  public spinnerFile = false;
  public step = 1;
  public message = null;
  public userData;
  public key_stock;

  public file = [];
  public numFile = 0;

  public foto = [];
  public numFoto = 0;

  public video = [];
  public numVideo = 0;

  public nome_stock;
  public richiedi_valutazione="2";

  constructor(public formBuilder: FormBuilder, public db: AngularFireDatabase, private storage: AngularFireStorage, public dialog: MatDialog, public afAuth: AngularFireAuth, private router: Router, private http: Http) {
    this.stockForm = formBuilder.group({
      nome_stock: new FormControl('', Validators.compose([Validators.required])),
      proposta_stock :  new FormControl(''),
    });

    //this.goModalStockCreate();
    this.afAuth.auth.onAuthStateChanged( user => {
      if (user){
        console.log(user);
        this.db.object('users/'+user.uid).valueChanges().subscribe( action =>{
          console.log(action);
          if(action){
            this.userData=action;
          }
        });
      }else{
        this.router.navigate(['/login/accedi']);
      }
    });
  }

  ngOnInit() {

  }


  updateStep1(){
    var self = this;
    this.message = null;
    this.spinnerAvanti = true;
    if (!this.stockForm.value.nome_stock){
      this.spinnerAvanti = false;
      this.message='Inserisci un nome identificativo per il tuo stock.'
    } else {

      this.nome_stock = this.stockForm.value.nome_stock

      if(!this.key_stock){
        var docData = {
          created_at:  Date.now(),
          step:1,
          user: {
              name: this.userData.name,
              surname: this.userData.surname,
              uid: this.userData.uid,
              email: this.userData.email,
          },
          nome_stock : this.nome_stock,
        };

        self.db.database.ref('stock').push(docData).then(c=>{
          console.log(c.key);
          self.key_stock = c.key;
          self.spinnerAvanti=false;
          self.step=2;
        });
      }else{
        self.db.database.ref('stock/'+this.key_stock).update({
          nome_stock : this.nome_stock,
        }).then(c=>{
          console.log(c);
          self.spinnerAvanti=false;
          self.step=2;
        });
      }
    }
  }

  updateStep2(){
    this.message = null;
    console.log(this.file);
    if(this.file.length==0){
      this.message='Carica almeno un file prima di continuare.';
    }else{
      var self = this
      this.spinnerAvanti = true;

      self.db.database.ref('stock/'+this.key_stock).update({
        step:2,
      }).then(c=>{
        console.log(c);
        self.spinnerAvanti=false;
        self.step=3;
      });
    }
  }

  updateStep3(){
    this.message = null;
    console.log(this.file);
    var self = this
    this.spinnerAvanti = true;

    self.db.database.ref('stock/'+this.key_stock).update({
      step:3,
    }).then(c=>{
      console.log(c);
      self.spinnerAvanti=false;
      self.step=4;
    });
  }

  updateStep4(){
    this.message = null;
    console.log(this.file);
    var self = this
    this.spinnerAvanti = true;

    self.db.database.ref('stock/'+this.key_stock).update({
      step:4,
    }).then(c=>{
      console.log(c);
      self.spinnerAvanti=false;
      self.step=5;
    });
  }
  
  updateStep5(){
    this.message = null;
    if (this.richiedi_valutazione=='2' && !this.stockForm.value.proposta_stock){
      this.spinnerAvanti = false;
      this.message='Inserisci una valutazione per il tuo stock.'
    } else {
      var self = this
      this.spinnerAvanti = true;
      self.db.database.ref('stock/'+this.key_stock).update({
        step:5,
        richiedi_valutazione: this.richiedi_valutazione,
        proposta_stock: this.stockForm.value.proposta_stock,
      }).then(c=>{
        console.log(c);
        self.spinnerAvanti=false;

        self.http.post('https://us-central1-groupock-app.cloudfunctions.net/sendStockEmail', {
          stockId: self.key_stock,
        })
        .subscribe(
          res => {
            console.log(res);
          },
          err => {
            console.log(err);         
          }
        )


        self.router.navigate(['/stock-list']);
        this.goModalStockCreate();
      });
    }
  }

  goModalStockCreate(){
    let dialogRef = this.dialog.open(ModalStockCreateComponent,{
  
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /* CARICO UN INSIEME DI FILE */
  onFileChangedFile(event) {
    this.message=null;
    this.spinnerFile=true;
    var self = this;
    let results = event.target.files;
    
    for (var i=0; i<event.target.files.length; i++) {
      let namefile = results[i].name;
      const file = results[i];
      const filePath = 'content_stock/'+self.key_stock+'/'+namefile;
      const fileRef = self.storage.ref(filePath);
      const task = self.storage.upload(filePath, file);

      task.snapshotChanges().pipe(
        finalize(() => {
          let uploadSubscribe = fileRef.getDownloadURL().subscribe(data=>{
            let myFileURL = data;
            this.file.push({"url":myFileURL, "name_file":namefile});
            self.numFile = self.file.length;
            console.log(self.file);
            self.db.database.ref('stock/'+self.key_stock).update({
              file: self.file,
            }).then(c=>{
              console.log(c);
              self.spinnerFile=false;
            });
          });
        })
      ).subscribe(c=>{
        console.log(c);
      });
    }
  }

  /* ELIMINAZIONE SINGOLO FILE */
  removeFile(num, nameFile): void {
    var self = this;

    var storageRef = this.storage.ref('content_stock/');
    var desertRef = storageRef.child(this.key_stock).child(nameFile);
    // Delete the file
    desertRef.delete();

    this.file.splice(num,1);
    console.log(this.file);
    self.numFile = self.file.length;
    self.db.database.ref('stock/'+self.key_stock).update({
      file: self.file,
    });
  }

  openLink(url){
    window.open(url);
  }


  /* CARICO UN INSIEME DI FILE */
  onFileChangedFoto(event) {
    this.spinnerFile=true;
    var self = this;
    let results = event.target.files;
    
    for (var i=0; i<event.target.files.length; i++) {
      let namefile = results[i].name;
      const file = results[i];
      const filePath = 'content_stock/'+self.key_stock+'/foto/'+namefile;
      const fileRef = self.storage.ref(filePath);
      const task = self.storage.upload(filePath, file);

      task.snapshotChanges().pipe(
        finalize(() => {
          let uploadSubscribe = fileRef.getDownloadURL().subscribe(data=>{
            let myFileURL = data;
            this.foto.push({"url":myFileURL, "name_file":namefile});
            self.numFoto = self.foto.length;
            console.log(self.foto);
            self.db.database.ref('stock/'+self.key_stock).update({
              foto: self.foto,
            }).then(c=>{
              console.log(c);
              self.spinnerFile=false;
            });
          });
        })
      ).subscribe(c=>{
        console.log(c);
      });
    }
  }

  /* ELIMINAZIONE SINGOLO FILE */
  removeFoto(num, nameFile): void {
    var self = this;

    var storageRef = this.storage.ref('content_stock/');
    var desertRef = storageRef.child(this.key_stock).child('foto').child(nameFile);
    // Delete the file
    desertRef.delete();

    this.foto.splice(num,1);
    console.log(this.foto);
    self.numFoto = self.foto.length;
    self.db.database.ref('stock/'+self.key_stock).update({
      foto: self.foto,
    });
  }

  /* CARICO UN INSIEME DI FILE */
  onFileChangedVideo(event) {
    this.spinnerFile=true;
    var self = this;
    let results = event.target.files;
    
    for (var i=0; i<event.target.files.length; i++) {
      let namefile = results[i].name;
      const file = results[i];
      const filePath = 'content_stock/'+self.key_stock+'/video/'+namefile;
      const fileRef = self.storage.ref(filePath);
      const task = self.storage.upload(filePath, file);

      task.snapshotChanges().pipe(
        finalize(() => {
          let uploadSubscribe = fileRef.getDownloadURL().subscribe(data=>{
            let myFileURL = data;
            this.video.push({"url":myFileURL, "name_file":namefile});
            self.numVideo = self.video.length;
            console.log(self.video);
            self.db.database.ref('stock/'+self.key_stock).update({
              video: self.video,
            }).then(c=>{
              console.log(c);
              self.spinnerFile=false;
            });
          });
        })
      ).subscribe(c=>{
        console.log(c);
      });
    }
  }

  /* ELIMINAZIONE SINGOLO FILE */
  removeVideo(num, nameFile): void {
    var self = this;

    var storageRef = this.storage.ref('content_stock/');
    var desertRef = storageRef.child(this.key_stock).child('video').child(nameFile);
    // Delete the file
    desertRef.delete();

    this.video.splice(num,1);
    console.log(this.video);
    self.numVideo = self.video.length;
    self.db.database.ref('stock/'+self.key_stock).update({
      video: self.video,
    });
  }
}
