import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {OwlCarousel} from 'ngx-owl-carousel';
import { CrystalLightbox } from 'ngx-crystal-gallery';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-stock-list-admin-detail',
  templateUrl: './stock-list-admin-detail.component.html',
  styleUrls: ['./stock-list-admin-detail.component.scss']
})
export class StockListAdminDetailComponent implements OnInit {

    
  @ViewChild('owlElement') owlElement: OwlCarousel;

  public idstock;
  public stock_detail;

  public spinnerStock = true;
  public user;

  public imgGallery = [];

  constructor(public db: AngularFireDatabase, private route: ActivatedRoute, public lightbox: CrystalLightbox, private sanitizer: DomSanitizer, ) { 

    this.route.params.subscribe((params: ParamMap) => {
      this.idstock = params['idstock']; 
      var self = this;
      this.db.object('stock/'+this.idstock).valueChanges().subscribe( action =>{
        console.log(action);
        if(action){
          this.stock_detail = action;

          this.db.object('users/'+action['user'].uid).valueChanges().subscribe( actionUser =>{
            if(actionUser){
              self.user = actionUser;
              self.spinnerStock=false;
            }
          });

          this.stock_detail['foto'].forEach(element => {
            //var urlImg = self.sanitizer.bypassSecurityTrustResourceUrl(element.url);	;
            self.imgGallery.push(
              {
                path: element.url,
                
              },
            );            
          });
        }
      });
    });
  }

  ngOnInit() {
  }

  
  openLink(url){
    window.open(url);
  }

  preview() {
    this.owlElement.previous([200])
    //duration 200ms
  }
  next() {
    this.owlElement.next([200])
    //duration 200ms
  }
}
