import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import {MatSnackBar, MAT_SNACK_BAR_DATA, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-modal-reset-password',
  templateUrl: './modal-reset-password.component.html',
  styleUrls: ['./modal-reset-password.component.scss']
})
export class ModalResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public message;
  public spinnerReset = false;

  constructor(public snackBar: MatSnackBar, public authProvider: AuthService, public formBuilder: FormBuilder, public dialogRef: MatDialogRef<ModalResetPasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.resetPasswordForm = formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
    });
  }

  ngOnInit() {

  }

  resetPassword(){
    this.spinnerReset=true;
    var self = this;
    if (!this.resetPasswordForm.valid){
      console.log(this.resetPasswordForm.value);
    } else {
      this.authProvider.resetPassword(this.resetPasswordForm.value.email)
      .then((user) => {
        self.chiudi();
        self.spinnerReset=false;
        self.message = 'Abbiamo inviato una email con le istruzuioni per poter resettare la tua password.';
        self.snackBar.openFromComponent(SnackMessResetPwdComponent,{
          duration: 4500,
          data:{
            message: self.message,
          }
        });
      }, (error) => {
        self.spinnerReset=false;
        if(error.message=='There is no user record corresponding to this identifier. The user may have been deleted.'){
          self.message = 'Non ci sono utenti che corrispondono a questo indirizzo email.';
        }else{
          self.message = error.message;
        }
      });
    }
  }

  chiudi(): void {
    this.dialogRef.close();
  }
}


@Component({
  templateUrl: 'message.html',
})
export class SnackMessResetPwdComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

  }
}
