import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// FLEX LAYOUT
import { FlexLayoutModule } from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

//FIREBASE
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

// MATERIAL
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule, MatNativeDateModule, MatDatepickerModule, MatSnackBarModule, MatChipsModule, MatButtonModule, MatRadioModule, MatSlideToggleModule, MatSidenavModule, MatCardModule, MatGridListModule, MatMenuModule, MatToolbarModule, MatCheckboxModule, MatDialogModule, MatTableModule, MatPaginatorModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule  } from '@angular/material/form-field'
import { MatSliderModule } from '@angular/material/slider';

//ANGULAR
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient }     from '@angular/common/http';
import { HttpModule } from '@angular/http';

//MODULES
import { HeaderComponent } from './modules/header/header.component';
import { MenuComponent } from './modules/menu/menu.component';
import { ModalStockCreateComponent } from './modules/modal-stock-create/modal-stock-create.component';
import { ModalResetPasswordComponent, SnackMessResetPwdComponent } from './modules/modal-reset-password/modal-reset-password.component';

//PAGES
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ContactComponent } from './pages/contact/contact.component';
import { StockListComponent } from './pages/stock/stock-list/stock-list.component';
import { StockListAdminComponent } from './pages/stock/stock-list-admin/stock-list-admin.component';
import { StockCreateComponent } from './pages/stock/stock-create/stock-create.component';
import { StockListAdminDetailComponent } from './pages/stock/stock-list-admin-detail/stock-list-admin-detail.component';
import { TermsComponent } from './pages/terms/terms.component';

//SERVICES
import { AuthService } from './service/auth.service';

import { OwlModule } from 'ngx-owl-carousel';
import {CrystalGalleryModule} from 'ngx-crystal-gallery';

export const environment = {
  firebase: {
    apiKey: "AIzaSyDt4l5c6nZunRKs9QVfCVgEmoZ_il9B-W4",
    authDomain: "groupock-app.firebaseapp.com",
    databaseURL: "https://groupock-app.firebaseio.com",
    projectId: "groupock-app",
    storageBucket: "groupock-app.appspot.com",
    messagingSenderId: "563381357937",
  },
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    MenuComponent,
    ContactComponent,
    StockListComponent,
    StockListAdminComponent,
    StockCreateComponent,
    ModalStockCreateComponent,
    StockListAdminDetailComponent,
    TermsComponent,
    ModalResetPasswordComponent,
    SnackMessResetPwdComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatChipsModule,
    MatButtonModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatCardModule,
    MatGridListModule, 
    MatMenuModule, 
    MatToolbarModule, 
    MatCheckboxModule,
    MatDialogModule, 
    MatTableModule, 
    MatPaginatorModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSliderModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireDatabaseModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    OwlModule,
    CrystalGalleryModule,
  ],
  providers: [
    AuthService,
  ],
  bootstrap: [AppComponent],

  entryComponents: [
    ModalStockCreateComponent,
    ModalResetPasswordComponent,
    SnackMessResetPwdComponent,
  ]
})
export class AppModule { }


//firebase deploy --except functions