import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  @Input('menuIndex')
  menuIndex;

  public menu = false;
  public userData;
  
  constructor(private router: Router, public db: AngularFireDatabase, public afAuth: AngularFireAuth, public authProvider: AuthService,) {
    this.afAuth.auth.onAuthStateChanged( user => {
      if (user){
        console.log(user);
        this.db.object('users/'+user.uid).valueChanges().subscribe( action =>{
          console.log(action);
          if(action){
            this.userData=action;
          }
        });
      }
    });
  }

  ngOnInit() {
  }

  openMenu(){
    if(this.menu){
      this.menu=false;
    }else{
      this.menu=true;
    }
  }

  gotoLogin(param){
    this.router.navigate(['/login/'+param]);
  }

  logout(){
    console.log('Esci');
    this.authProvider.logoutUser();
    this.menu=false;
    this.userData=null;
    this.router.navigate(['/home']);
  }

  gotoPage(link){
    console.log(link);
    this.router.navigate(['/'+link]);
  }
}
