import { Injectable } from '@angular/core';
import { Router, CanActivate,  ActivatedRouteSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';
import { Http } from '@angular/http';

@Injectable()
export class AuthService {

  public user: firebase.User;
  public userData;
  public authState$: Observable<firebase.User>;

  constructor(public afAuth: AngularFireAuth, private router: Router, private db: AngularFireDatabase, private http: Http) {

  }

  loginUser(email: string, password: string): Promise<any> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }
  
  logoutUser() {
    this.afAuth.auth.signOut();
  }

  resetPassword(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  signupUser(email: string, password: string, name: string, surname: string, azienda: string, tipo: string, indirizzo: string, paese: string, partita_iva: string, telefono: string): Promise<any> {
    var self = this;
    return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then( newUser => {
      console.log('Utente id: ', newUser.user.uid);
      //inserisco il nuovo utente in firestore
      var uid = newUser.user.uid;
 
      var docData = {
        created_at:  Date.now(),
        uid: newUser.user.uid,
        name: name,
        surname: surname,  
        email : email,
        azienda: azienda,
        tipo: tipo,
        indirizzo: indirizzo,
        paese: paese,
        partita_iva: partita_iva,
        telefono: telefono,
      };
      self.db.database.ref('users/'+uid).set(docData).then(c=>{
        console.log('utente creato'+ docData);

        var userData = {
          name: name,
          surname: surname,  
          email : email,
        }

        self.http.post('https://us-central1-groupock-app.cloudfunctions.net/welcomeEmail', {
          userId: uid,
        })
        .subscribe(
          res => {
            console.log(res);
          },
          err => {
            console.log(err);         
          }
        )
      });
    });
  }

  changePassword(oldPassword,newPassword,email) {
    return this.afAuth.auth.currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(email, oldPassword)).then(data => {
          return this.afAuth.auth.currentUser.updatePassword(newPassword).then(function() {
                return;
          }).catch(function(error) {
            console.log(error);
            return error;
          });
    }).catch(error => {
        return error;
    })
  }
}
