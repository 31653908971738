import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Http } from '@angular/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public spinnerContact = false;
  public message = null;
  public contactForm: FormGroup;

  constructor(public formBuilder: FormBuilder, private http: Http) {
    this.contactForm = formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      surname: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
      message: new FormControl('', Validators.compose([Validators.required])),
      condizione: new FormControl(false),
    });
  }

  ngOnInit() {
    
  }

  contactSend(){
    var self = this;
    this.message = null;
    this.spinnerContact = true;
    if (!this.contactForm.valid || this.contactForm.value.condizione==false){
      console.log('Compila tutti i campi');
      this.message = 'Compila tutti i campi';
      this.spinnerContact = false;
    }else{

      var name = this.contactForm.value.name;
      var surname = this.contactForm.value.surname;
      var email = this.contactForm.value.email;
      var message = this.contactForm.value.message;

      self.http.post('https://us-central1-groupock-app.cloudfunctions.net/sendRequestEmail', {
        name:  name,
        surname: surname,
        email: email,
        message: message,
      })
      .subscribe(
        res => {
          this.spinnerContact = false;
          this.message = 'Email inviata con successo.';
          console.log(res);
        },
        err => {
          this.spinnerContact = false;
          this.message = 'Si è verificato un errore. Riprova.';
          console.log(err);         
        }
      )
    }
  }

  goToLinkedin(){
    window.open('https://www.linkedin.com/company/groupock');
  }
}
